import React from "react";
import { useEffect } from "react";
import { Container } from "..";

interface FacebookChatProps {
  pageId: string;
  themeColor: string;
}

interface FacebookInitProps {
  pageId: string;
  themeColor: string;
}

export function init({ pageId, themeColor }: FacebookInitProps) {
  var chatbox = document.getElementById("fb-customer-chat");
  chatbox.setAttribute("page_id", pageId);
  chatbox.setAttribute("attribution", "biz_inbox");
  chatbox.setAttribute("theme_color", themeColor);

  console.log(pageId);

  (window as any).fbAsyncInit = function () {
    FB.init({
      xfbml: true,
      version: "v11.0",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

export function cleanup() {
  (function (d, id) {
    var target = d.getElementById(id);
    if (target) {
      target.parentNode.removeChild(target);
    }
  })(document, "facebook-jssdk");

  delete (window as any).FB;
}

export const FacebookChat = ({ pageId, themeColor }: FacebookChatProps) => {
  useEffect(() => {
    init({ pageId, themeColor });

    return () => {
      cleanup();
    };
  }, []);

  return (
    <Container id="fb-wrapper">
      <Container id="fb-root"></Container>

      <Container id="fb-customer-chat" className="fb-customerchat"></Container>
    </Container>
  );
};
